import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

const StyledItem = styled(ListGroupItem)`
  &:hover {
    border-color: var(--info) !important;
  }
`

export const ListItem = ({ data, onClick }) => (
  <StyledItem className="mb-3 border rounded" role="button" onClick={onClick}>
    <Row className="align-items-center">
      <Col style={{ fontSize: '0.85rem' }}>
        <span className="font-weight-bold">{data.name || data.db_entity}</span>
      </Col>
    </Row>

    <Row className="mt-2">
      <Col className="small">
        <ListGroup horizontal>
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
            Table: {data.tableName}
          </ListGroupItem>
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
            Entity: {data.db_entity || '-'}
          </ListGroupItem>
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
            Label: {data.label || '-'}
          </ListGroupItem>
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
            Similarity: {data.similarity || '-'}
          </ListGroupItem>
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
            Input Expression: {data.input_expr || '-'}
          </ListGroupItem>
        </ListGroup>
      </Col>
    </Row>
  </StyledItem>
)

ListItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    tableName: PropTypes.string,
    name: PropTypes.string,
    db_entity: PropTypes.string,
    input_expr: PropTypes.string,
    label: PropTypes.string,
    similarity: PropTypes.number,
  }),
}

export default ListItem
