import React from 'react'
import { Container } from 'reactstrap'

import SummarySection from 'components/Catalog/RequestPageView/DataView/OutputTab/SummarySection'
import DataSection from 'components/Catalog/RequestPageView/DataView/OutputTab/DataSection'

const OutputTab = () => (
  <Container fluid>
    <SummarySection />
    <DataSection />
  </Container>
)

export default OutputTab
