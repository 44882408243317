import React from 'react'
import { useParams } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import {
  Col,
  Row,
  Container,
} from 'reactstrap'

import NodeList from 'components/Catalog/RequestPageView/DataView/NodesTab/NodeList'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetRequestQuery } from 'services/catalogs'

const Nodes = () => {
  const { uuid, workspaceUUID } = useParams()

  const { data = {}, isLoading } = useGetRequestQuery({ uuid, workspaceUUID })

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={12} className="d-flex flex-column">
          {isLoading ? (
            <Spinner />
          ) : (
            isEmpty(data.nodes) ? (
              <Row className="flex-grow-1 align-items-center">
                <Col className="text-muted text-center">No data</Col>
              </Row>
            ) : (
              <NodeList data={data.nodes} />
            )
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Nodes
