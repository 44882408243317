import React from 'react'
import { Nav } from 'reactstrap'

import StyledTab from 'components/Catalog/Shared/StyledTab'

import { useActiveTab } from 'hooks/useActiveTab'

const NavigationTabs = () => {
  const activeTab = useActiveTab('output')

  return (
    <Nav tabs className="px-3 border-bottom">
      <StyledTab name="output" activeTab={activeTab}>
        Output
      </StyledTab>
    </Nav>
  )
}

export default NavigationTabs
