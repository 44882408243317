const ICONS = {
  APP: 'ni ni-app',
  ATOM: 'ni ni-atom',
  ARROWS_SPIN: 'fa-solid fa-arrows-spin',
  CANCEL_ICON: 'fa-solid fa-xmark',
  BELL: 'ni ni-bell-55',
  BOOK: 'fas fa-book',
  BRIEFCASE: 'ni ni-briefcase-24',
  BULB: 'ni ni-bulb-61',
  BULLET_LIST: 'ni ni-bullet-list-67',
  BUTTON_POWER: 'ni ni-button-power',
  CALENDAR_GRID: 'ni ni-calendar-grid-58',
  CAPS_SMALL: 'ni ni-caps-small',
  CHECK_CIRCLE: 'fas fa-check-circle',
  CIRCLE: 'ni ni-circle-08',
  CLOUD_UPLOAD_ALT: 'fas fa-cloud-upload-alt',
  CODE: 'fa-solid fa-code',
  COG: 'fa fa-cog fa-2x',
  DATABASE: 'fas fa-database',
  ELLIPSIS_H: 'fas fa-ellipsis-h',
  ELLIPSIS_V: 'fas fa-ellipsis-v',
  EMAIL: 'ni ni-email-83',
  EYE: 'fas fa-eye',
  EYE_SLASH: 'fas fa-eye-slash',
  FAT_ADD: 'ni ni-fat-add',
  FAT_REMOVE: 'ni ni-fat-remove',
  FILE: 'fas fa-file',
  FILE_ALT: 'fas fa-file-alt',
  FILE_CODE: 'fas fa-file-code',
  FOLDER: 'fa-regular fa-folder-open',
  ORG: 'fa-solid fa-people-group',
  HAT: 'ni ni-hat-3',
  HOME: 'fas fa-laptop-house',
  ICON_BELL: 'far fa-bell',
  ICON_BELL_EXC: 'fal fa-bell-exclamation',
  LAPTOP: 'ni ni-laptop',
  LEFT: 'fas fa-angle-left',
  LOCK_CIRCLE_OPEN: 'ni ni-lock-circle-open',
  MODELS: 'fa-solid fa-chart-line',
  NOTE: 'fa-regular fa-note-sticky',
  PAPER_DIPLOMA: 'ni ni-paper-diploma',
  PEOPLE_ARROWS: 'fa-solid fa-group-arrows-rotate',
  RIGHT: 'fas fa-angle-right',
  SEARCH: 'fas fa-search',
  SETTINGS: 'ni ni-settings',
  SIDENAV_TOGGLER_LINE: 'sidenav-toggler-line',
  SINGLE_COPY: 'ni ni-single-copy-04',
  SNOWFLAKE: 'fas fa-snowflake',
  SNOWFLAKE_V2: 'far fa-snowflake',
  SPACESHIP: 'ni ni-spaceship',
  TABLE: 'fas fa-table',
  TIMES: 'fas fa-times',
  TIM_ALIGN_CENTER: 'tim-icons icon-align-center',
  TIM_BULLET_LIST: 'tim-icons icon-bullet-list-67',
  TIM_CHECK_2: 'tim-icons icon-check-2',
  TIM_CLOUD_UPLOAD: 'tim-icons icon-cloud-upload-94',
  TIM_SIMPLE_REMOVE: 'tim-icons icon-simple-remove',
  TRASH: 'fas fa-trash',
  ZOOM_SPLIT_IN: 'ni ni-zoom-split-in',
  SMART_WORK_BOOKS: 'fas fa-grip-vertical',
  GEAR: 'fas fa-gear',
  PLUS: 'fas fa-plus',
  ARROW_LEFT: 'fas fa-arrow-left',
  CLOSE: 'fas fa-close',
  STREAM: 'fas fa-stream',
  SERVER: 'fa-solid fa-network-wired',
  DASH: 'fa-solid fa-gauge-high',
  JOBS: 'fa-solid fa-cubes',
  SORT_ASC: 'fa-solid fa-arrow-down-short-wide',
  SORT_DESC: 'fa-solid fa-arrow-down-wide-short',
  DELETE: 'fa-regular fa-trash-can',
  HISTORY_UPLOAD: 'fa-solid fa-plus',
  HISTORY_EDIT: 'fa-solid fa-code-merge',
  REGULAR_BARS: 'fa-solid fa-bars',
  ANGLE_RIGHT: 'fas fa-angle-right',
  ANGLE_DOWN: 'fas fa-angle-down',
  ANGLE_UP: 'fas fa-angle-up',
  ARROW_RIGHT: 'fas fa-arrow-right',
  PENCIL_SQUARE: 'fa-solid fa-pen-to-square',
  PENCIL: 'fas fa-pencil',
  SAVE_ICON: 'fa-solid fa-check',
  TRIANGLE_EXCLAMATION: 'fas fa-triangle-exclamation',
  USER: 'fa-regular fa-user',
  FILTER: 'fa-solid fa-sliders',
  SHARE: 'fa-solid fa-arrow-up-right-from-square',
  ID_CARD: 'fa-regular fa-address-card',
  DRAG: 'fas fa-grip-vertical',
  DIAGRAM: 'fa-solid fa-diagram-project',
  SQUARE_UP_RIGHT: 'fa-solid fa-up-right-from-square',
  RIGHT_BRACKET: 'fa-solid fa-right-from-bracket',
  SCHEDULERS: 'fa-regular fa-clock',
  SITEMAP: 'fa-solid fa-sitemap',
  TABLE_COLUMNS: 'fa-solid fa-table-columns',
  INFO: 'fa-solid fa-circle-info',
  ARROW_UP_SHORT_WIDE: 'fa-solid fa-arrow-up-short-wide',
  ARROW_DOWN_WIDE_SHORT: 'fa-solid fa-arrow-down-wide-short',
}

export default ICONS
