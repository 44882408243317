import React from 'react'
import { useParams } from 'react-router-dom'

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap'

import { useGetRequestQuery } from 'services/catalogs'

import ICONS from 'helpers/iconConstants'

const SummarySection = () => {
  const { uuid, workspaceUUID } = useParams()
  const { data = {} } = useGetRequestQuery({ uuid, workspaceUUID })

  return (
    <Card className="rounded-0 position-relative shadow-none">
      <CardHeader className="bg-white position-sticky top-0" style={{ zIndex: 1040 }}>
        <Row>
          <Col className="d-flex align-items-center">
            <i className={ICONS.STREAM} />
            <h3 className="m-0 pl-2 d-inline-block">Summary</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="flex-wrap">
          <Col>
            <span className="d-block text-muted small">Rows</span>
            <span className="small">{data?.data.data.length}</span>
          </Col>
          <Col>
            <span className="d-block text-muted small">Columns</span>
            <span className="small">{data?.data.schema.fields.length}</span>
          </Col>
          <Col>
            <span className="d-block text-muted small">Tables used</span>
            <span className="small">{Object.keys(data.nodes).length}</span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default SummarySection
