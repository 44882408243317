import React, { useMemo, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import GraphVis from 'react-graph-vis'
import uniqueId from 'lodash/uniqueId'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'

import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import Legend from 'components/Catalog/GlossaryPage/Content/Legend'
import NodeInfo from 'components/Catalog/GlossaryPage/Content/NodeInfo'
import ScrollBar from 'components/Custom/ScrollBar'

import { useGetGlossaryPathQuery, useGetGlossaryQuery } from 'services/catalogs'
import ICONS from 'helpers/iconConstants'
import { GRAPH_LABELS } from 'helpers/constants'
import { removeYes } from 'helpers/utils'

const GRAY = '#32325d'
const LIGHT_GRAY = '#bdc0c3'

const KnowledgeGraphSection = ({ glossary }) => {
  const { workspaceUUID, uuid } = useParams()
  const [selectedNode, setSelectedNode] = useState({})

  const { data: glossaryData = [] } = useGetGlossaryQuery(workspaceUUID)
  const { data = [], isFetching } = useGetGlossaryPathQuery({ hash: uuid, workspaceUUID }, { skip: !uuid })

  useEffect(() => {
    setSelectedNode({})
  }, [uuid])

  const graph = useMemo(() => {
    const formattedGraph = { nodes: [], edges: [], labels: [] }
    const nodes = {}
    data.forEach((path) => {
      const [from, label, to, ...rest] = path
      nodes[from.hash] = { id: +from.hash, label: removeYes(from.name) }
      nodes[to.hash] = { id: +to.hash, label: removeYes(to.name) }
      formattedGraph.edges.push({
        from: +from.hash, to: +to.hash, label, length: 230,
      })
      if (rest.length) {
        const [_label, _to] = rest
        nodes[_to.hash] = { id: +_to.hash, label: removeYes(_to.name) }
        formattedGraph.edges.push({
          from: +to.hash, to: +_to.hash, label: _label, length: 230,
        })
      }
    })

    // Remove duplicates
    formattedGraph.edges = uniqWith(formattedGraph.edges, isEqual)

    Object.keys(nodes).forEach((hash) => {
      const { labels: [label], ...rest } = glossaryData.find(glData => glData.hash === hash)
      const _label = rest.label
      delete rest.label
      if (GRAPH_LABELS[label]) {
        nodes[hash] = {
          ...nodes[hash],
          ...rest,
          _label,
          color: {
            border: GRAPH_LABELS[label].border,
            background: GRAPH_LABELS[label].background,
            highlight: {
              background: GRAPH_LABELS[label].background,
            },
          },
          font: {
            color: ['CATEGORY', 'DATA_TYPE', 'GEO_COLUMN', 'MEASURE', 'PERSON', 'PRODUCT'].includes(label) ? '#fff' : '#000',
          },
          ...uuid === hash && {
            margin: 12,
            borderWidth: 3,
            color: {
              border: '#000',
              background: GRAPH_LABELS[label].background,
              highlight: {
                border: GRAPH_LABELS[label].border,
                background: GRAPH_LABELS[label].background,
              },
            },
          },
        }
        formattedGraph.labels.push(label)
      }
    })
    formattedGraph.nodes.push(...Object.values(nodes))
    formattedGraph.labels = [...new Set(formattedGraph.labels)]
    return formattedGraph
  }, [uuid, data])

  const version = useMemo(uniqueId, [isFetching, data])

  return (
    <Card className="rounded-0 h-100 shadow-none">
      <CardHeader>
        <Row>
          <Col className="d-flex align-items-center">
            <i className={ICONS.DIAGRAM} />
            <h3 className="m-0 pl-2 d-inline-block">
              {removeYes(glossary?.name)}
              {glossary?.db_entity ? ` - ${removeYes(glossary?.db_entity)}` : ''}
            </h3>
          </Col>
        </Row>
      </CardHeader>

      <ScrollBar>
        <CardBody className="h-100">
          {isFetching ? (
            <Spinner />
          ) : (
            <Row className="h-100">
              <Col xs={12} className="position-relative overflow-hidden h-100">
                <GraphVis
                  key={version}
                  graph={graph}
                  options={{
                    autoResize: true,
                    height: '100%',
                    width: '100%',

                    // Layout
                    layout: {
                      randomSeed: 1,
                    },

                    // Physics
                    physics: {
                      solver: 'hierarchicalRepulsion',
                      hierarchicalRepulsion: {
                        nodeDistance: 230,
                      },
                      stabilization: {
                        enabled: true,
                        iterations: 2000,
                      },
                    },

                    // Interaction
                    interaction: {
                      hover: true,
                    },

                    // Nodes
                    nodes: {
                      value: 1,
                      widthConstraint: 70,
                      shape: 'circle',
                      labelHighlightBold: false,
                      // scaling: {
                      //   label: {
                      //     drawThreshold: 7,
                      //     enabled: true,
                      //     max: 20,
                      //     min: 10,
                      //   },
                      // },
                      font: {
                        size: 12,
                        face: '"Helvetica Neue", sans-serif',
                        align: 'center',
                      },
                      chosen: {
                        node(values, _, selected) {
                          if (selected) values.borderWidth = 3
                        },
                      },
                    },

                    // Edges
                    edges: {
                      width: 1,
                      widthConstraint: 50,
                      labelHighlightBold: false,
                      smooth: {
                        type: 'curvedCCW',
                        roundness: 0.2,
                      },
                      font: {
                        size: 9,
                        strokeWidth: 0,
                        face: '"Helvetica Neue", sans-serif',
                        color: 'transparent',
                      },
                      color: {
                        color: LIGHT_GRAY,
                      },
                      arrows: {
                        to: {
                          enabled: true,
                          scaleFactor: 0.5,
                        },
                        from: {
                          enabled: false,
                          scaleFactor: 0.5,
                        },
                      },
                      chosen: {
                        edge(values) {
                          values.width = 2
                          values.color = GRAY
                        },
                        label(values) {
                          values.color = GRAY
                          values.strokeWidth = 2
                        },
                      },
                    },
                  }}
                  events={{
                    select({ nodes }) {
                      const [nodeId] = nodes
                      setSelectedNode(graph.nodes.find(({ id }) => id === nodeId))
                    },
                  }}
                />
                <Legend labels={graph.labels} />
                <NodeInfo node={selectedNode} />
              </Col>
            </Row>
          )}
        </CardBody>
      </ScrollBar>
    </Card>
  )
}

KnowledgeGraphSection.propTypes = {
  glossary: PropTypes.shape({
    name: PropTypes.string,
    db_entity: PropTypes.string,
  }),
}

export default KnowledgeGraphSection
