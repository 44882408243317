import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import {
  Col,
  Row,
  Collapse,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'
import { numberToHuman, removeYes } from 'helpers/utils'
import { SOURCE_TYPES } from 'helpers/constants'

const ActionIcon = styled.i`
  &:hover {
    color: var(--gray-dark) !important;
  }
`

const StyledItem = styled(ListGroupItem)`
border-color: ${props => props.selected ? 'var(--info)' : '#e9ecef'} !important;
  &:hover {
    border-color: var(--info) !important;
  }
`

export const ListItem = ({
  data,
  onClick,
  selected,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <StyledItem
        className={classnames('mb-3 border rounded', { 'ml-5 mr-2': !children })}
        role="button"
        onClick={onClick}
        selected={selected}
      >
        <Row className="align-items-center">
          <Col style={{ fontSize: '0.85rem' }}>
            <i className={classnames('mr-2', {
              [`${ICONS.SNOWFLAKE} text-info`]: data.source_type === SOURCE_TYPES.SNOWFLAKE,
              [`${ICONS.DATABASE} text-green`]: !Object.values(SOURCE_TYPES).includes(data.source_type),
            })} />
            <span className="font-weight-bold">{data.name}</span>
          </Col>

          {children && (
            <Col xs="auto" className="small">
              <ActionIcon className={`${ICONS.GEAR} mr-2 text-muted`} role="button" />
              <ActionIcon
                className={classnames('text-muted', {
                  [ICONS.ANGLE_UP]: isOpen,
                  [ICONS.ANGLE_DOWN]: !isOpen,
                })}
                role="button"
                onClick={toggle}
              />
            </Col>
          )}
        </Row>

        {children ? (
          <Row className="mt-2">
            <Col className="small">
              <ListGroup horizontal>
                <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
                  <i className={`${ICONS.DATABASE} mr-1`} />
                  {data.db_name}
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        ) : (
          <Row className="mt-2">
            <Col className="small">
              <ListGroup horizontal>
                <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
                  <i className={`${ICONS.TABLE} mr-1`} />
                  {data.type}
                </ListGroupItem>
                <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
                  {numberToHuman(data.rows, 'row')}
                </ListGroupItem>
                <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
                  {numberToHuman(data.columns, 'column')}
                </ListGroupItem>
                <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
                  <i className={`${ICONS.DATABASE} mr-1`} />
                  {data.db_name}
                </ListGroupItem>
                {data?.schema && (
                  <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted">
                    <i className={`${ICONS.SITEMAP} mr-1`} />
                    {removeYes(data.schema)}
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
          </Row>
        )}
      </StyledItem>

      {children && (
        <Collapse isOpen={isOpen}>
          {children}
        </Collapse>
      )}
    </>
  )
}

ListItem.defaultProps = {
  selected: false,
}

ListItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    db_name: PropTypes.string,
    schema: PropTypes.string,
    type: PropTypes.string,
    source_type: PropTypes.string,
  }),
  selected: PropTypes.bool,
  children: PropTypes.node,
}

export default ListItem
