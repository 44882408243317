import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
} from 'reactstrap'

export const ActionButton = ({
  label,
  className,
  size,
  ...options
}) => (
  <Button
    outline
    className={`border ${className}`}
    size={size}
    {...options}
  >
    {label}
  </Button>
)

ActionButton.defaultProps = {
  size: 'sm',
}

ActionButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  size: PropTypes.string,
}
