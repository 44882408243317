import React from 'react'
import PropTypes from 'prop-types'
import ICONS from 'helpers/iconConstants'

const SortIcon = ({ column }) => {
  if (!column.isSorted) return null

  return column.isSortedDesc
    ? <i className={ICONS.ARROW_DOWN_WIDE_SHORT} />
    : <i className={ICONS.ARROW_UP_SHORT_WIDE} />
}

SortIcon.propTypes = {
  column: PropTypes.shape({
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }),
}

export default SortIcon
