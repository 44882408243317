import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { formatDateAndTime } from 'helpers/utils'
import {
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'

const StyledItem = styled(ListGroupItem)`
  &:hover {
    border-color: var(--info) !important;
  }
`

export const ListItem = ({ data, onClick }) => (
  <StyledItem className="mb-3 border rounded" role="button" onClick={onClick}>
    <Row className="align-items-center">
      <Col style={{ fontSize: '0.85rem' }}>
        <i className={`${ICONS.SEARCH} mr-2 text-info`} />
        <span className="font-weight-bold">{data?.name}</span>
      </Col>
    </Row>

    <Row>
      <Col className="small mt-2">
        <ListGroup horizontal className="justify-content-between">
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 text-muted text-capitalize">
            {data?.type}
          </ListGroupItem>
          <ListGroupItem className="border-0 px-0 pb-0 pt-1 text-muted">
            {formatDateAndTime(data?.created)}
          </ListGroupItem>
        </ListGroup>
      </Col>
    </Row>
  </StyledItem>
)

ListItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    created: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

export default ListItem
