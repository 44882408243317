import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import {
  useGlobalFilter, usePagination, useTable,
} from 'react-table'
import {
  Col,
  Row,
  ListGroup,
} from 'reactstrap'

import AssetSlidePanel from 'components/Catalog/AssetsPage/AssetSlidePanel'
import ScrollBar from 'components/Custom/ScrollBar'
import { ReactTableFilter } from 'components/Catalog/Shared/FilterInput'
import Pagination from 'components/Catalog/Shared/Pagination'
import ListItem from 'components/Catalog/AssetsPage/ListItem'

const AssetList = ({ data, filterable }) => {
  const history = useHistory()
  const { workspaceUUID, section } = useParams()
  const [isSlideOpen, setSlideOpen] = useState(false)

  const onChildClick = ({
    source_uuid, name, db_name, schema,
  }) => history.push(`/workspaces/${workspaceUUID}/catalog/${section}/${source_uuid}?db_name=${db_name}&schema=${schema}&name=${name}`)

  const onParentClick = ({ target }) => {
    if (target?.tagName === 'I' && target?.role === 'button') {
      if (target.classList.contains('fa-gear')) setSlideOpen(true)
    }
  }

  const columns = useMemo(() => ['name'].map(accessor => ({ accessor })), [])

  const options = useTable(
    {
      initialState: { pageSize: 25, pageIndex: 0 },
      columns,
      data,
    },
    useGlobalFilter,
    usePagination,
  )

  const {
    page,
    setGlobalFilter,
    state: { globalFilter },
  } = options

  return (
    <>
      {filterable && (
        <Row>
          <Col xs={12} className="py-3 bg-white border-bottom">
            <ReactTableFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} size="bg" placeholder="Type asset name..." />
          </Col>
        </Row>
      )}

      <ScrollBar>
        <Row className="justify-content-center m-0">
          <Col xs={12} md={10} lg={7}>
            <ListGroup flush className="my-3">
              <ListItem
                data={{ name: 'snowflake/your-connection', source_type: 'snowflake', db_name: page?.[0]?.original?.db_name }}
                onClick={onParentClick}
              >
                {page.map((row, i) => (
                  <ListItem
                    data={row.original}
                    onClick={() => onChildClick(row.original)}
                    key={i}
                  />
                ))}
              </ListItem>
            </ListGroup>
          </Col>
        </Row>
      </ScrollBar>

      <AssetSlidePanel isOpen={isSlideOpen} setOpen={setSlideOpen} />

      {options.pageCount > 1 && (
        <Row>
          <Col xs={12} className="py-3 bg-white">
            <Pagination {...options} />
          </Col>
        </Row>
      )}
    </>
  )
}

AssetList.defaultProps = {
  data: [],
  filterable: false,
}

AssetList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  filterable: PropTypes.bool,
}

export default AssetList
