import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

const CustomSpinner = ({ height, width }) => (
  <div className="w-100 align-items-center h-100 d-flex justify-content-center">
    <Spinner color="primary" style={{ height, width }}>
      Loading...
    </Spinner>
  </div>
)

export const SmallSpinner = () => <CustomSpinner height="2rem" width="2rem" />
export const LoadingSpinner = () => <CustomSpinner height="3rem" width="3rem" />

CustomSpinner.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}
