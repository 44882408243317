import React from 'react'
import { Container } from 'reactstrap'

import DataSection from 'components/Catalog/RequestPageView/KnowledgeView/OutputTab/DataSection'

const OutputTab = () => (
  <Container fluid>
    <DataSection />
  </Container>
)

export default OutputTab
