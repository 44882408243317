import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import {
  Col,
  Row,
  ListGroup,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import ListItem from 'components/Catalog/RequestPageView/DataView/NodesTab/ListItem'

const NodeList = ({ data }) => {
  const history = useHistory()
  const { workspaceUUID } = useParams()

  const onRowClick = hash => history.push(`/workspaces/${workspaceUUID}/catalog/glossary/${hash}`)

  return (
    <ScrollBar>
      <Row className="justify-content-center m-0">
        <Col xs={12} md={10} lg={8}>
          <ListGroup flush className="my-3">
            {Object.keys(data).map((nodeName) => {
              const nodes = data[nodeName]
              return nodes.map(nodeAttrs => (
                <ListItem
                  key={nodeAttrs.hash}
                  onClick={() => onRowClick(nodeAttrs.hash)}
                  data={{
                    ...nodeAttrs,
                    tableName: nodeName,
                  }}
                />
              ))
            })}
          </ListGroup>
        </Col>
      </Row>
    </ScrollBar>
  )
}

NodeList.propTypes = {
  data: PropTypes.shape({}),
}

export default NodeList
