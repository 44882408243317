import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import AssetList from 'components/Catalog/AssetsPage/AssetList'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetWorkspaceCatalogsQuery } from 'services/workspaces'

const AssetsPage = () => {
  const { workspaceUUID } = useParams()

  const { data = [], isLoading } = useGetWorkspaceCatalogsQuery(workspaceUUID)

  return (
    <Row className="h-100">
      <Col xs={12} className="d-flex flex-column">
        {isLoading ? (
          <Spinner />
        ) : (
          data.length ? (
            <AssetList data={data} />
          ) : (
            <Row className="flex-grow-1 align-items-center">
              <Col className="text-muted text-center">No data</Col>
            </Row>
          )
        )}
      </Col>
    </Row>
  )
}

export default AssetsPage
