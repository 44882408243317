import divianApi from './api'
import { customRequest } from 'helpers/request'
import remove from 'lodash/remove'
import capitalize from 'lodash/capitalize'

export const workspacesApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getWorkspaces: build.query({
      query: () => 'workspaces',
      providesTags: ['Workspaces'],
    }),
    getWorkspace: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}`,
      providesTags: ['Workspace'],
    }),
    getWorkspaceDocuments: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}/documents`,
      providesTags: ['WorkspaceDocuments'],
    }),
    getWorkspaceDatasets: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}/datasets`,
      providesTags: ['WorkspaceDatasets'],
    }),
    getWorkspaceSources: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}/sources`,
      providesTags: ['WorkspaceSources'],
    }),
    getWorkspaceModels: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}/models`,
      providesTags: ['WorkspaceModels'],
    }),
    getWorkspaceMembers: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}/members`,
      providesTags: ['WorkspaceMembers'],
    }),
    getWorkspaceCatalogs: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}/catalogs`,
      providesTags: ['WorkspaceCatalogs'],
    }),
    getWorkspaceOntology: build.query({
      query: workspaceUUID => `workspaces/${workspaceUUID}/ontology`,
      providesTags: ['WorkspaceOntology'],
    }),
    shareAsset: build.mutation({
      query: ({ uuid, type, workspaceUUID }) => ({
        url: `workspaces/${workspaceUUID}/${type}`,
        method: 'POST',
        body: { uuid },
      }),
      invalidatesTags: ['WorkspaceDatasets', 'Workspaces', 'WorkspaceSources', 'WorkspaceModels', 'WorkspaceDocuments'],
    }),
    addWorkspace: build.mutation({
      query: () => ({
        url: 'workspaces',
        method: 'POST',
      }),
      invalidatesTags: ['Workspaces'],
    }),
    updateWorkspace: build.mutation({
      query: ({ workspaceUUID, ...patch }) => ({
        url: `workspaces/${workspaceUUID}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ workspaceUUID, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(workspacesApi.util.updateQueryData('getWorkspace', workspaceUUID, (workspace) => {
          Object.assign(workspace, patch)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: ['Workspaces'],
    }),
    uploadWorkspaceLogo: build.mutation({
      queryFn: async (...args) => {
        const [{ workspaceUUID, base64File },,,fetchWithBQ] = args
        const { data: { url, fields } } = await fetchWithBQ({ url: `workspaces/${workspaceUUID}/logo` })

        const file = await fetch(base64File)
          .then(res => res.blob())
          .then(blob => new File([blob], 'avatar',{ type: 'image/png' }))

        await customRequest({
          url,
          method: 'POST',
          multipart: true,
          data: { ...fields, file, acl: 'public-read' },
        })
        return fetchWithBQ({
          url: `workspaces/${workspaceUUID}/logo`,
          method: 'POST',
        })
      },
      invalidatesTags: ['Workspace'],
    }),
    deleteWorkspaceAsset: build.mutation({
      query: ({ workspaceUUID, uuid, type }) => ({
        url: `workspaces/${workspaceUUID}/${type}/${uuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ workspaceUUID, type, uuid }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(workspacesApi.util.updateQueryData(`getWorkspace${capitalize(type)}`, workspaceUUID, (draft) => {
          remove(draft, object => object.uuid === uuid)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: ['ContainerSources'],
    }),
    deleteWorkspace: build.mutation({
      query: uuid => ({
        url: `workspaces/${uuid}`,
        method: 'DELETE',
      }),
      onQueryStarted: (uuid, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(workspacesApi.util.updateQueryData('getWorkspaces', undefined, (draft) => {
          remove(draft, workspace => workspace.uuid === uuid)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: ['Workspaces'],
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetWorkspacesQuery,
  useGetWorkspaceQuery,
  useGetWorkspaceDocumentsQuery,
  useGetWorkspaceDatasetsQuery,
  useGetWorkspaceSourcesQuery,
  useGetWorkspaceMembersQuery,
  useGetWorkspaceOntologyQuery,
  useGetWorkspaceModelsQuery,
  useGetWorkspaceCatalogsQuery,
  useShareAssetMutation,
  useAddWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useUploadWorkspaceLogoMutation,
  useDeleteWorkspaceAssetMutation,
  useDeleteWorkspaceMutation,
} = workspacesApi
