import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import ListItem from 'components/Catalog/AssetsPage/ListItem'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetCatalogQuery, useGetRequestQuery } from 'services/catalogs'

const UsedTable = ({ name, onClick, table }) => {
  const { uuid, workspaceUUID } = useParams()
  const { data: requestData = {} } = useGetRequestQuery({ uuid, workspaceUUID })

  const { data = {}, isLoading } = useGetCatalogQuery({
    uuid: requestData.source_uuid,
    db_name: requestData.database,
    name,
    schema: requestData.schema,
    workspaceUUID,
  })

  if (isLoading) return (
    <div className="py-3">
      <Spinner />
    </div>
  )

  return (
    <ListItem data={data.graph_meta} selected={table === name} onClick={onClick} />
  )
}

UsedTable.propTypes = {
  name: PropTypes.string,
  table: PropTypes.string,
  onClick: PropTypes.func,
}

export default UsedTable
