import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { format } from 'sql-formatter'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ListGroup,
  Container,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import UsedTable from 'components/Catalog/RequestPageView/DataView/LogTab/UsedTable'
import TablePreview from 'components/Catalog/RequestPageView/DataView/LogTab/TablePreview'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { BadgeTag } from 'components/Catalog/Shared/Tags'

import { useGetRequestQuery } from 'services/catalogs'

import ICONS from 'helpers/iconConstants'

const LogTab = () => {
  const { uuid, workspaceUUID } = useParams()
  const [table, setTable] = useState(null)

  const { data = {}, isLoading } = useGetRequestQuery({ uuid, workspaceUUID })

  useEffect(() => {
    if (isLoading) return
    setTable(Object.keys(data.nodes)[0])
  }, [isLoading])

  if (isLoading) return <Spinner />

  return (
    <Container fluid>
      <Card className="rounded-0 position-relative shadow-none">
        <CardHeader className="position-sticky top-0 bg-white" style={{ zIndex: 1040 }}>
          <Row>
            <Col className="d-flex align-items-center">
              <i className={ICONS.TABLE_COLUMNS} />
              <h3 className="m-0 pl-2 d-inline-block">Summary</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={6} className="border-right">
              <div className="small mb-3 text-muted">Executed query:</div>
              <ScrollBar autoHeight autoHeightMax="45vh">
                <pre><code>{format(data.query, { uppercase: true })}</code></pre>
              </ScrollBar>
            </Col>
            <Col xs={6} className="">
              <div className="small text-muted mb-3 d-flex align-items-end">
                Tables used:
                <BadgeTag label={Object.keys(data.nodes).length} className="ml-2" />
              </div>
              <ScrollBar autoHeight autoHeightMax="45vh">
                <ListGroup flush className="mb-3">
                  {Object.keys(data.nodes).map(name => (
                    <UsedTable
                      key={name}
                      name={name}
                      table={table}
                      onClick={() => setTable(name)}
                    />
                  ))}
                </ListGroup>
              </ScrollBar>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <TablePreview tableName={table} />
    </Container>
  )
}

export default LogTab
