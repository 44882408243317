import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
} from 'reactstrap'

import SlidePanel from 'components/Shared/SlidePanel'

import ICONS from 'helpers/iconConstants'

const AssetSlidePanel = ({
  isOpen,
  setOpen,
}) => (
  <SlidePanel
    isOpen={isOpen}
    onRequestClose={() => setOpen(false)}
    title=""
  >
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col>
            <h5 className="h3 mb-0"><i className={`${ICONS.SERVER} text-primary mr-2`}/>Connection parameters</h5>
          </Col>
        </Row>
      </CardHeader>

      <CardBody>
        <Table size="sm" style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col width={'140px'} style={{ minWidth: '140px' }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td>Username</td>
              <td>Divian</td>
            </tr>
            <tr>
              <td>Password</td>
              <td>********</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </SlidePanel>
)

AssetSlidePanel.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default AssetSlidePanel
