import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import lodashKeys from 'lodash/keys'

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap'

import StickyTable from 'components/Catalog/Shared/StickyTable'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetTableHeaderQuery } from 'services/sources'
import { useGetRequestQuery } from 'services/catalogs'

import ICONS from 'helpers/iconConstants'

const TablePreview = ({ tableName }) => {
  const { uuid, workspaceUUID } = useParams()

  const { data: requestData = {} } = useGetRequestQuery({ uuid, workspaceUUID })

  const { data = {}, isFetching } = useGetTableHeaderQuery({
    uuid: requestData.source_uuid,
    databaseName: requestData.database,
    tableName,
    schemaName: requestData.schema,
  }, { skip: !tableName })

  const { header, body } = useMemo(() => {
    const tableData = data.header?.data || []

    return {
      body: tableData,
      header: lodashKeys(tableData[0]),
    }
  }, [data.header?.data])

  if (!tableName) return <Spinner />

  return (
    <Card className="rounded-0 position-relative shadow-none">
      <CardHeader className="position-sticky top-0 bg-white" style={{ zIndex: 1040 }}>
        <Row>
          <Col className="d-flex align-items-center">
            <i className={ICONS.SNOWFLAKE} />
            <h3 className="m-0 pl-2 d-inline-block">{tableName}</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <StickyTable header={header} body={body} isLoading={isFetching} maxHeight="69vh" />
      </CardBody>
    </Card>
  )
}

TablePreview.propTypes = {
  tableName: PropTypes.string,
}

export default TablePreview
