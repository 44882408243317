import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  Container,
  Row,
  Col,
  CardHeader,
} from 'reactstrap'

import { FilterInput } from 'components/Catalog/Shared/FilterInput'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import Navigation from 'components/Catalog/Navigation'
import AssetsPage from 'components/Catalog/AssetsPage'
import AssetPageView from 'components/Catalog/AssetPageView'
import GlossaryPage from 'components/Catalog/GlossaryPage'
import RequestsPage from 'components/Catalog/RequestsPage'
import RequestPageView from 'components/Catalog/RequestPageView'

import { useToken } from 'hooks/useToken'
import divianApi from 'services/api'
import { withAlerts } from 'contexts/alertsProvider'

import ICONS from 'helpers/iconConstants'

const CATALOG_ICONS = {
  assets: ICONS.EYE,
  discover: ICONS.SEARCH,
  glossary: ICONS.HAT,
  requests: ICONS.DATABASE,
  support: ICONS.SETTINGS,
}

const Catalog = ({ alerts }) => {
  const [loading, token] = useToken()
  const history = useHistory()
  const dispatch = useDispatch()
  const { workspaceUUID, section, uuid } = useParams()

  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  if (!loading && !token) return <Redirect to={{ pathname: '/login', state: { from: history.location } }} />
  if (!section) return <Redirect to={{ pathname: `/workspaces/${workspaceUUID}/catalog/assets` }} />

  const onChange = ({ target }) => setValue(target.value)

  const executeQuestion = async (question) => {
    try {
      setIsLoading(true)
      const { request_uuid } = await dispatch(divianApi.endpoints.getDiscover.initiate({ question, workspaceUUID })).unwrap()
      history.push(`/workspaces/${workspaceUUID}/catalog/discover/${request_uuid}`)
    } catch (err) {
      alerts.error({ message: 'Wit.ai is not responding. Please, try again.' })
    } finally { setIsLoading(false) }
  }

  return (
    <div className="h-100vh">
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col xs="auto" className="p-0">
            <Navigation />
          </Col>

          <Col className="d-flex flex-column">
            <Row className="bg-white">
              <Col>
                <CardHeader className="py-2 px-0">
                  <Row className="align-items-center">
                    <Col>
                      <i className={`${CATALOG_ICONS[section]} mr-2`} />
                      <span className="text-capitalize">{section}</span>
                    </Col>
                    <Col xs="auto">
                      <i className={`${ICONS.ICON_BELL} mr-3 text-muted ca-hover`} role="button" />
                      <i className={`${ICONS.USER} text-muted ca-hover`} role="button" />
                    </Col>
                  </Row>
                </CardHeader>
              </Col>
            </Row>
            <Row className="py-3 bg-white border-bottom">
              <Col />
              <Col xs={6}>
                <FilterInput
                  onChange={onChange}
                  value={value}
                  placeholder="Type your request here..."
                  onKeyPress={e => (e.code === 'Enter' || e.code === 'NumpadEnter') && executeQuestion(value)}
                  onIconClick={() => { executeQuestion(value) }}
                  disabled={isLoading}
                />
              </Col>
              <Col />
            </Row>

            <Row className="flex-grow-1">
              {isLoading ? (
                <Spinner />
              ) : (
                <Col>
                  {section === 'assets' && ((uuid && <AssetPageView />) || <AssetsPage />)}
                  {section === 'glossary' && <GlossaryPage />}
                  {section === 'discover' && ((uuid && <RequestPageView />) || <RequestsPage />)}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

Catalog.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(Catalog)
